frappe.ui.form.on('Sales Order', {
    refresh: function (frm) {
        prosel.projects.refreshOptions(frm)
    },
    custom_project_names: function (frm) {
        // frm.set_df_property('grading_subject', 'options', ['option a', 'option b'])
        frm.fields_dict['custom_proyectos_disponibles'].$wrapper.empty();
        frm.refresh()
    },
    custom_agregar_proyecto: function (frm) {
        const value = frm.doc.custom_nombre_de_projecto
        const options = JSON.parse(frm.doc.custom_project_names || "[]")
        options.push(value)
        frm.set_value("custom_project_names", JSON.stringify(options))
        frm.set_value("custom_nombre_de_projecto", null)

    }
});


frappe.ui.form.on('Sales Order Item', {
    form_render: function (frm, cdt, cdn) {
        const row = locals[cdt][cdn];
        var current_row = frm.fields_dict["items"].grid.grid_rows_by_docname[row.name];
        // debugger
        // frm.set_df_property("custom_proyecto_original", "options", JSON.parse(frm.doc.custom_project_names))
        current_row.set_field_property("custom_proyecto_original", "options", JSON.parse(frm.doc.custom_project_names))

    }
}
)