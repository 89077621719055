//custom_project_names
frappe.provide('prosel.projects');

$.extend(prosel.projects, {
    refreshOptions: function refreshOptions(frm) {
        frm.fields_dict['custom_nombre_de_projecto'].$input?.off('keypress').on('keypress', function (event) {
            // Check if Enter key is pressed
            if (event.key === 'Enter' || event.keyCode === 13) {
                event.preventDefault(); // Prevent the default action (optional)
                // debugger
                const value = frm.fields_dict['custom_nombre_de_projecto'].$input[0].value
                const options = JSON.parse(frm.doc.custom_project_names || "[]")
                options.push(value)
                frm.set_value("custom_project_names", JSON.stringify(options))
                frm.set_value("custom_nombre_de_projecto", null)
                frm.fields_dict['custom_nombre_de_projecto'].$input[0].value = ""

            }
        });
        const options = JSON.parse(frm.doc.custom_project_names || "[]")
        function onDeleteProject(index) {
            let funcOptions = JSON.parse(frm.doc.custom_project_names || "[]")
            funcOptions.splice(index, 1)
            frm.set_value("custom_project_names", JSON.stringify(funcOptions))
        }

        frm.fields_dict['custom_proyectos_disponibles'].$wrapper.empty();
        options.forEach((label, index) => {
            let button_id = `dynamic-button-${index}`;

            // Append button HTML
            frm.fields_dict['custom_proyectos_disponibles'].$wrapper.append(`
            <button id="${button_id}" class="btn btn-primary" style="margin: 5px;">
                ${label}
            </button>
        `);

            // Bind click event
            $(`#${button_id}`).click(() => onDeleteProject(index));
        });
    }
})

